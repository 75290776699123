import React from "react";
import { Container } from "react-bootstrap";
import SliderComponent from "./slider/slider";

import "../pages.css";

const About = () => {
  return (
    <div className="About">
      <Container>
        <img
          src="../meandmysidekicksm.png"
          alt="me and my sidekick"
          className="img img-fluid"
        />
        <h1>Who I am & my skills</h1>
        <p>
          I consider myself a true global citizen. Born in Germany, raised in
          Brussels, Geneva and Singapore, I have now settled down in Zurich.
          Originally having studied Hotel Management, I "fell" into banking
          straight out of university and have remained there for 15 years,
          before finding my true passion in coding. Better late than never. I
          have completed the SheCodes Bootcamp, a 12-months program, in 7
          months. Below is an overview of my skills attained thus far.
        </p>
        <SliderComponent />
        <p className="attribution">
          Fabulous icons created by{" "}
          <a
            href="https://www.flaticon.com/authors/freepik"
            title="Freepik icons"
            target="_blank"
            rel="noreferrer">
            Freepik
          </a>
          ,{" "}
          <a
            href="https://www.flaticon.com/authors/viconsdesign"
            title="ViconsDesign icons"
            target="_blank"
            rel="noreferrer">
            ViconsDesign
          </a>
          ,{" "}
          <a
            href="https://www.flaticon.com/authors/cobynecz"
            title="Cobynecz icons"
            target="_blank"
            rel="noreferrer">
            Cobynecz{" "}
          </a>
          - flaticon
        </p>
      </Container>
    </div>
  );
};

export default About;
